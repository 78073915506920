import { ReactiveComponent } from "~/commons/component/ReactiveComponent";
import { Component, Watch } from "~/commons/component/decorators";

import { ajax } from '~/services/ajax'

import { formatDate } from '~/commons/lang/dates'

import './add-or-edit.css'

@Component()
export class ComposePackageAddOrEditView extends ReactiveComponent {
  date = []  //销售时间
  showSwiperDialog = false // 轮播图弹窗显示/隐藏
  swiperDialogTitle = '' // 轮播图弹窗 标题
  is_caution = false //弹窗告诫 显示/隐藏
  swiperDialogData = { url: '', comment: '', content: '' } // 弹窗内容

  composePackageData = {
    name: null,
    alias_name: null,
    sale_type: null,
    price: null,
    underline_price: null,
    quantity: null,
    sale_channel: null,
    device_code: null,
    begin_time: null,
    end_time: null,
    package_card_ids: null,
    delay_coupon_ids: null,
    price_coupon_ids: null,
    pic_url: '',
    carousels: [],
    description: ''
  };


  // 编辑器参数
  editorOption = {
    //编辑器初始化
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, false] }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
      ],
    },
    placeholder: "请输入套餐说明",
    readOnly: this.$router.currentRoute.query.id ? true : false
  }

  saleChannelOptions = [
    { id: 1, name: "对内销售" },
    { id: 2, name: "对外销售" },
  ];  //销售方式

  packageCardsOptions = []  //套餐卡Options

  delayCouponsOptions = []  //延时卷Options

  priceCouponsOptions = []  //优惠卷Options


  created() {
    // 页面路径有带id参数则是查看功能
    if (this.$router.currentRoute.query.id) {
      this.getpackageComposeData()
    }
    this.getAllOptions()
  }

  // 是否禁用效果
  isDisabled() {
    if (this.$router.currentRoute.query.id) {
      return true
    }
    return false
  }

  // 时间格式化
  handleDate(time) {
    this.date = time
    this.composePackageData.begin_time = this.date[0] && formatDate(this.date[0])
    this.composePackageData.end_time = this.date[1] && formatDate(this.date[1])
  }

  // 获取套餐卡Options，优惠卷Options，延时卷Options
  getAllOptions() {
    // 获取套餐卡Options
    ajax('/api/packageCompose/getPackageCards',
      {
        method: 'GET'
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          this.packageCardsOptions = data.list
        }
      }
    )
    // 获取优惠卷Options
    ajax(`/api/packageCompose/getCoupons?coupon_type=${1}`,
      {
        method: 'GET'
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          this.priceCouponsOptions = data.list
        }
      })
    // 获取延时卷Options
    ajax(`/api/packageCompose/getCoupons?coupon_type=${2}`,
      {
        method: 'GET'
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          this.delayCouponsOptions = data.list
        }
      })
  }

  // 查看组合套餐 - composePackageData数据回显
  getpackageComposeData() {
    ajax(`/api/packageCompose/${this.$router.currentRoute.query.id}`,
      {
        method: 'GET'
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message ?? '获取数据失败', type: 'error' })
        } else {
          this.composePackageData = {
            ...data.compose_package,
            carousels: JSON.parse(data.compose_package.carousels === '' ? '{}' : data.compose_package.carousels)
          }
          this.date = [data.compose_package.begin_time, data.compose_package.end_time]
         // this.handleAmount() //查看功能 页面加载就显示组合套餐成本
        }
      })
  }

  // 计算组合套餐成本价格
  handleAmount() {
    ajax('/api/packageCompose/calAmount',
      {
        method: 'POST',
        body: {
          package_card_ids: this.composePackageData.package_card_ids,
          delay_coupon_ids: this.composePackageData.delay_coupon_ids,
          price_coupon_ids: this.composePackageData.price_coupon_ids
        }
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.body.msg ?? '获取数据失败', type: 'error' })
        } else {
          this.composePackageData.price = data.amount
        }
      })
  }


  // 上传图片校验逻辑
  beforeImageUpload(file) {
    console.log('file', file);
    if (!/^image\//.test(file.type)) {
      this.$message.error(`上传图片格式非法!`)

      return false
    }

    const MAX_SIZE = 2 * (1 << 20)

    if (file.size > MAX_SIZE) {
      this.$message.error("上传图片大小不能超过 2MB!")

      return false
    }

    return true
  }

  // 图片自定义上传逻辑
  doUploadFile(file, callback) {
    const formData = new FormData()

    formData.append('image', file)

    this.$http
      .post('/api/upload_image', formData, { emulateJSON: true })
      .then(
        res => {
          if (res.body.code === 200) {
            callback(res.body.data.path)
          } else {
            this.$message.error(res.body.msg)
          }
        },
        res => {
          this.$message.error(res.body.msg ?? res.body.data.msg)
        }
      )
  }

  // pic_url图片自定义上传
  handleUpload(data) {
    this.doUploadFile(data.file, path => {
      this.composePackageData.pic_url = path
    })
  }

  // 轮播图弹窗 swiperDialogData.url自定义上传
  handleUploadSwiper(data) {
    this.doUploadFile(data.file, path => {
      this.swiperDialogData.url = path
    })
  }

  // 弹出轮播图弹窗逻辑 - 新建/编辑
  addOrEditSwiperDialog(row) {
    // 弹窗数据清空
    this.swiperDialogData = { url: '', comment: '', content: '' }
    this.is_caution = false // 告诫隐藏

    if (row) {
      this.swiperDialogTitle = '编辑轮播图'
      this.swiperDialogData = { ...row }
    } else {
      this.swiperDialogTitle = '新建轮播图'
    }
    this.showSwiperDialog = true
  }

  // 关闭轮播图弹窗逻辑 - 新建/编辑
  cancelSwiperDialog() {
    this.swiperDialogData = {
      url: '',
      remark: '',
      content: ''
    }
    this.is_caution = false // 告诫隐藏

    this.showSwiperDialog = false
  }


  // 轮播图弹窗 - 提交逻辑
  submitSwiperDialog() {
    if (!this.swiperDialogData.url || !this.swiperDialogData.comment || !this.swiperDialogData.content) return this.is_caution = true

    if (this.swiperDialogData.id) {
      // 编辑逻辑
      this.composePackageData.carousels = this.composePackageData.carousels.map(item => {
        if (item.id === this.swiperDialogData.id) {
          return item = { ...this.swiperDialogData }
        }
        return item
      })
    } else {
      // 新增逻辑 - id作用：1.区分新增还是编辑 2.编辑时根据id找到要修改的数据对象
      this.composePackageData.carousels.push({ id: Date.now(), ...this.swiperDialogData })
    }

    this.$message({ type: 'success', message: '操作成功' });

    this.showSwiperDialog = false
  }

  // 轮播图 - 删除逻辑
  delSwiperDialog(row) {
    this.$confirm('是否删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      // 删除
      this.composePackageData.carousels = this.composePackageData.carousels.filter(item => item.id != row.id)
      this.$message({ type: 'success', message: '删除成功!' });
    })
  }




  // 提交逻辑
  handleSubmit() {
    // console.log('composePackageData', {...this.composePackageData, carousels:JSON.stringify(this.composePackageData.carousels.map(item => { if(item.id){delete item.id} return item  }))});
    ajax('/api/packageCompose',
      {
        method: 'POST',
        body: {
          ...this.composePackageData,
          price: this.composePackageData.sale_type === 1 ? this.composePackageData.price : 0, // 销售方式如果是兑换  会隐藏套餐售价输入框  故price要传0
          underline_price: this.composePackageData.sale_type === 1 ? this.composePackageData.underline_price : 0 , // 销售方式如果是兑换  会隐藏套餐售价输入框  故underline_price要传0
          carousels: JSON.stringify(this.composePackageData.carousels.map(item => { if (item.id) { delete item.id } return item }))
        }
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message ?? '获取数据失败', type: 'error' })
        } else {
          this.$message({ message: '操作成功', type: 'success' })
          this.$router.push({ name: 'compose_package' })
        }
      }
    )
  }


  render() {
    return (
      <div class="box-tag-block">
        <div class="box-body el-col-md-16">
          <el-form value={this.composePackageData} onInput={(e) => (this.composePackageData = e)} >
            <el-form-item label="套餐名称" class='el-col-md-12'>
              <el-input value={this.composePackageData.name} onInput={(e) => (this.composePackageData.name = e)} disabled={this.isDisabled()} />
            </el-form-item>

            <el-form-item label="套餐别名" class='el-col-md-12'>
              <el-input value={this.composePackageData.alias_name} onInput={(e) => (this.composePackageData.alias_name = e)} disabled={this.isDisabled()} />
            </el-form-item>

            <el-form-item label="销售方式">
              <el-radio-group value={this.composePackageData.sale_type} onInput={(e) => (this.composePackageData.sale_type = e)} disabled={this.isDisabled()}>
                <el-radio label={1}>销售</el-radio>
                <el-radio label={2}>兑换</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="发放数量" class='el-col-md-12'>
              <el-input value={this.composePackageData.quantity} onInput={(e) => (this.composePackageData.quantity = e)} disabled={this.isDisabled()} >
                <span slot="append">张</span>
              </el-input>
            </el-form-item>

            <el-form-item label="销售渠道" class='el-col-md-12'>
              <el-select placeholder="请选择销售渠道" value={this.composePackageData.sale_channel || ""} onInput={(e) => (this.composePackageData.sale_channel = e)} filterable clearable disabled={this.isDisabled()}>
                {this.saleChannelOptions.map((item) => (
                  <el-option label={item.name} value={item.id} />
                ))}
              </el-select>
            </el-form-item>

            <el-form-item label="绑定设备" class='el-col-md-12'>
              <el-input value={this.composePackageData.device_code} onInput={(e) => (this.composePackageData.device_code = e)} disabled={this.isDisabled()} />
            </el-form-item>

            <el-form-item label="销售时间" class='el-col-md-12'>
              <el-date-picker placeholder="选择活动日期范围" value={this.date} onInput={e => this.handleDate(e)} type="datetimerange" format="yyyy-MM-dd" clearable disabled={this.isDisabled()} />
            </el-form-item>

            <el-form-item label="选择套餐卡" class='el-col-md-12'>
              <el-select placeholder="请选择套餐卡" value={this.composePackageData.package_card_ids} onInput={e => this.composePackageData.package_card_ids = e} filterable disabled={this.isDisabled()} multiple>
                {
                  this.packageCardsOptions.map((v) => (
                    <el-option label={v.name} value={v.id} />
                  ))
                }
              </el-select>
            </el-form-item>

            <el-form-item label="选择延时卷" class='el-col-md-12'>
              <el-select placeholder="请选择延时卷" value={this.composePackageData.delay_coupon_ids} onInput={e => this.composePackageData.delay_coupon_ids = e} filterable disabled={this.isDisabled()} multiple >
                {
                  this.delayCouponsOptions.map((v) => (
                    <el-option label={v.alias} value={v.id} />
                  ))
                }
              </el-select>
            </el-form-item>

            <el-form-item label="选择优惠卷" class='el-col-md-12'>
              <el-select placeholder="请选择优惠卷" value={this.composePackageData.price_coupon_ids} onInput={e => this.composePackageData.price_coupon_ids = e} filterable disabled={this.isDisabled()} multiple >
                {
                  this.priceCouponsOptions.map((v) => (
                    <el-option label={v.alias} value={v.id} />
                  ))
                }
              </el-select>
            </el-form-item>

            {
              this.composePackageData.sale_type === 1 &&
              (
                  <section>
                    <el-form-item label="实付价格" class='el-col-md-14'>
                    <el-row >
                      <el-col span={19}>
                        <el-input value={this.composePackageData.price} onInput={(e) => (this.composePackageData.price = e)} disabled >
                          <span slot="append">元</span>
                        </el-input>
                      </el-col>
                      <el-col span={5}>
                        {
                          !this.$router.currentRoute.query.id &&
                          <el-button type="primary" onClick={() => this.handleAmount()} style="float: right;">计算</el-button>
                        }
                      </el-col>
                    </el-row>
                  </el-form-item>

                  <el-form-item label="划线价格">
                    <el-input value={this.composePackageData.underline_price} onInput={(e) => (this.composePackageData.underline_price = e)} style="width: 367px;" disabled={this.isDisabled()}>
                      <span slot="append">元</span>
                    </el-input>
                  </el-form-item>
                </section>
              )
            }

            <el-form-item label="弹窗底图图片" >
              <el-upload
                class="dialog-bottom-uploader ad-uploader"
                style="width: 650px;height: 300px;"
                action="/api/upload_image"
                http-request={(e) => this.handleUpload(e)}
                show-file-list={false}
                before-upload={(e) => this.beforeImageUpload(e)}
                disabled={this.isDisabled()}
              >
                {
                  this.composePackageData.pic_url ?
                    <img src={this.composePackageData.pic_url} style="width: 650px;height: 300px;" /> :
                    <i class="el-icon-plus avatar-uploader-icon" />
                }
              </el-upload>
            </el-form-item>

            <el-form-item label="轮播图片" style="width: 1000px;">
              {
                !this.$router.currentRoute.query.id &&
                <el-button type="primary" style="margin-bottom: 25px;" onClick={() => this.addOrEditSwiperDialog()} ><i class="el-icon-plus el-icon--left"></i>添加轮播图</el-button>
              }
              <el-table data={this.composePackageData.carousels} border style="width: 100%">
                <el-table-column label="轮播图(650 * 300)" scopedSlots={
                  {
                    default: scope => (
                      <img src={scope.row.url} style="width: 100%; height: 100%;" />
                    )
                  }
                } />
                <el-table-column prop="comment" label="轮播图备注" />
                <el-table-column prop="content" label="展示内容" />
                {
                  !this.isDisabled() &&
                  <el-table-column label="操作" scopedSlots={
                    {
                      default: ({ row }) => (
                        <span>
                          <el-button type="primary" size="small" onClick={() => this.addOrEditSwiperDialog(row)} >编辑</el-button>
                          <el-button type="danger" size="small" onClick={() => this.delSwiperDialog(row)} >删除</el-button>
                        </span>
                      )
                    }
                  } />
                }
              </el-table>
            </el-form-item>

            <el-form-item label="套餐说明">
              <quill-editor value={this.composePackageData.description} onInput={(e) => this.composePackageData.description = e} options={this.editorOption} />
            </el-form-item>

            <el-form-item>
              <div class='bottom-btn'>
                {
                  !this.$router.currentRoute.query.id && (
                    <el-button type="primary" onClick={() => this.handleSubmit()}>提交</el-button>
                  )
                }
                <el-button onClick={() => this.$router.push({ name: 'compose_package' })}>返回</el-button>
              </div>
            </el-form-item>


          </el-form>

          <el-dialog
            title={this.swiperDialogTitle}
            visible={this.showSwiperDialog}
            before-close={() => this.cancelSwiperDialog()}>
            <el-form value={this.swiperDialogData} onInput={(e) => (this.swiperDialogData = e)} ref="swiperRules" >
              <el-form-item label="轮播图(650 * 300)" rules={[{ required: true }]}>
                <el-upload
                  class="dialog-bottom-uploader ad-uploader"
                  action="/api/upload_image"
                  http-request={(e) => this.handleUploadSwiper(e)}
                  show-file-list={false}
                  before-upload={(e) => this.beforeImageUpload(e)}
                >
                  {
                    this.swiperDialogData.url ?
                      <img src={this.swiperDialogData.url} style="width: 650px;height: 300px;" /> :
                      <i class="el-icon-plus avatar-uploader-icon" />
                  }
                </el-upload>
              </el-form-item>

              <el-form-item label="轮播图备注" rules={[{ required: true }]} >
                <el-input type="textarea" value={this.swiperDialogData.comment} onInput={(e) => this.swiperDialogData.comment = e} />
              </el-form-item>

              <el-form-item label="展示内容" rules={[{ required: true }]} >
                <el-input type="textarea" value={this.swiperDialogData.content} onInput={(e) => this.swiperDialogData.content = e} />
                {
                  this.is_caution &&
                  <span>
                    <i class="my-weibiaoti1"></i><span class="colorF37800">请填写所有必填项</span>
                  </span>
                }
              </el-form-item>

            </el-form>
            <span slot="footer">
              <el-button onClick={() => this.cancelSwiperDialog()}>取 消</el-button>
              <el-button type="primary" onClick={() => this.submitSwiperDialog()}>确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    );
  }
}
