import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

import { pick } from 'lodash-es'

export
function DeviceEditViewProvider(SuperComponent) {
  @Component()
  class DeviceEditView extends SuperComponent {
    stagePackageOptions = []

    created() {
      this.$set(this.dialog, 'is_stages', 0)
      this.$set(this.dialog, 'stage_id', null)

      super.created()
    }

    mounted() {
      this.loadStagePackageOptionsForDevice()

      super.mounted()
    }

    get stagePackageAvailable() {
      if (!this.dialog.stage_id) {
        return this.stagePackageOptions
      }

      return this.stagePackageOptions.filter(v => (v.id === this.dialog.stage_id))
    }

    @Watch('dialog.supervisor_id')
    loadStagePackageOptionsForDevice() {
      ajax(
        '/api/get_supervisor_stage_package',
        {
          method: 'POST',
          body: {
            device_id: this.$route.params.id,
          },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            this.stagePackageOptions = data
          }
        }
      )
    }

    /** @override */
    handleSubmit() {
      this.changeLoading(true)

      const data = pick(
        this.dialog,
        [
          'id', 'company', 'agent_id', 'supervisor_id', 'network_id', 'is_config', 'dev_id', 'device_code', 'dtu_code', 'model', 'name', 'sim_id',
          'is_stages', 'stage_id',
        ]
      )

      this.updateDevice(data).then(() =>{
        this.$router.push({
          name: 'device',
          params: this.$route.params.search,
        })
      })
    }

    @Watch('dialog')
    handleDataInit() {
      if (this.dialog.is_stages == null) {
        this.$set(this.dialog, 'is_stages', (this.dialog.stage_id ? 1 : 0))
      }
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      root.classList.add('device-edit-main')

      const { value: containerView } = root.query({ as: <el-row />, parentAs: <el-form /> }).next()

      containerView?.children?.push(
        <el-col xs={ 24 } md={ 14 }>
          <el-form-item label="分期设备">
            <el-select placeholder="请选择是否分期" value={ this.dialog.is_stages } onInput={ e => (this.dialog.is_stages = Number(e)) } >
                <el-option value={ 1 } label="是" />
                <el-option value={ 0 } label="否" />
            </el-select>
          </el-form-item>
        </el-col>
      )

      if (this.dialog.is_stages) {
        containerView?.children?.push(
          <el-col xs={ 24 } md={ 18 }>
            <el-form-item label="分期套餐">
              <el-col xs={ 24 } md={ 18 }>
                <el-select placeholder="请选择分期套餐" value={ this.dialog.stage_id } onInput={ e => (this.dialog.stage_id = e) } clearable style="margin-bottom: 24px;">
                  {
                    this.stagePackageOptions.map(item => (
                      <el-option key={ item.id } value={ item.id } label={ item.title } />
                    ))
                  }
                </el-select>
              </el-col>

              <el-table data={ this.stagePackageAvailable } stripe cellStyle={ { textAlign: 'center' } } >
                <el-table-column label="分期名称" prop="title" />
                <el-table-column label="分期数量" prop="stage_time" />
                <el-table-column label="月套餐" scopedSlots={
                  {
                    default: scope => (
                      <span>{ scope.row.month_title } ({ scope.row.month_price }元)</span>
                    )
                  }
                } />
                <el-table-column label="季度套餐" scopedSlots={
                  {
                    default: scope => (
                      <span>{ scope.row.quarter_title } ({ scope.row.quarter_price }元)</span>
                    )
                  }
                } />
                <el-table-column label="半年套餐" scopedSlots={
                  {
                    default: scope => (
                      <span>{ scope.row.half_year_title } ({ scope.row.half_year_price }元)</span>
                    )
                  }
                } />
                <el-table-column label="买断套餐" prop="over_title" />
              </el-table>

            </el-form-item>
          </el-col>
        )
      }

      return vnode
    }
  }


  return DeviceEditView
}
