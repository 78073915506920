import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

export
  function NetworkEditViewProvider(SuperComponent) {
  @Component()
  class NetworkEditView extends SuperComponent {
    voicePackageList = this.getVoicePackageList()
    getVoicePackageList() {
      voicePackageData = []
      for (let i = 1; i < 6; i++) {
        voicePackageData.push({ value: i, label: `语音套餐${i}` })
      }
      return voicePackageData
    }

    downloadSoundExcel() {
      location.href = 'https://isf.sofocn.com/SOFOsoundpackage.docx'
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      if (this.show) {
        const { value: siblingView } = root.query({ as: <el-form-item label="所属经销点" /> }).next()

        siblingView?.after(
          <el-form-item label="设备类型" rules={this.rules}>
            <el-select placeholder="请选择设备类型" value={this.dialog.type || ''} onInput={e => (this.dialog.type = e)} size="small" filterable clearable>
              <el-option label="小按摩椅" value={1} />
              <el-option label="大按摩椅" value={2} />
            </el-select>
          </el-form-item>
          ,

          <el-form-item label="设备型号" rules={this.rules}>
            <el-input value={this.dialog.device_model} onInput={e => (this.dialog.device_model = e)} />
          </el-form-item>
          ,

          <el-form-item label="是否防占座">
            <el-radio-group value={this.dialog.occcpy_seat} onInput={e => (this.dialog.occcpy_seat = e)}>
              <el-radio label={0}>否</el-radio>
              <el-radio label={1}>是</el-radio>
            </el-radio-group>
          </el-form-item>
        )
      }


      if (this.dialog.sound) {
        const { value: voiceView } = root.query({ as: <el-form-item label="开启语音播报" /> }).next()

        voiceView?.after(
            <el-form-item label="选择语音套餐">
              <el-row>
                <el-col span={5}>
                  <el-select placeholder="请选择语言套餐" value={this.dialog.sound_package} onInput={e => (this.dialog.sound_package = e)} filterable clearable>
                    {
                      this.voicePackageList.map((item) => (
                        <el-option label={item.label} value={item.value} />
                      ))
                    }
                  </el-select>
                </el-col>
                <el-col span={10}>
                  <el-button type="primary" onClick={() => this.downloadSoundExcel()} style="margin-left: 20px;">下载语音套餐组合文档</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            ,

            <el-form-item label="选择音量">
              <el-input-number value={this.dialog.sound_volume} onInput={e => (this.dialog.sound_volume = Math.round(e))} min={0} max={9} />
            </el-form-item>
        )
      }

      return vnode
    }
  }


  return NetworkEditView
}
