import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

export
function FreeChargeNetworkViewProvider(SuperComponent) {
  @Component()
  class FreeChargeNetworkView extends SuperComponent {
    powerList = []
    deviceOptions = []

    checkedDeivces = []
    viewType = 2

    networkDataNormalized = false

    get title() {
      const { type } = this.$route.query

      for (const item of this.powerList) {
        if (item.type === +type) {
          return item.name
        }
      }

      return null
    }

    mounted() {
      console.log('[features] mounted')
      this.loadPowerList()
      this.loadDeviceOptions()
      // this.loadNetworkSettings()
    }

    loadPowerList() {
      ajax(
        '/api/power_list_index',
        {
          method: 'GET',
          // body: { type: this.$route.query.type, },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            console.log('[features] power_list', data)
            this.powerList = data
          }
        }
      )
    }

    loadDeviceOptions() {
      this.deviceOptions = [
        '7331', '7333', '7335', '7336', '7338', '9000', 'S3', 'S5',
      ]

      // select all by default
      this.checkedDeivces = this.deviceOptions.slice()
    }

    loadNetworkSettings() {
      ajax(
        '/api/network_contact',
        {
          method: 'POST',
          body: { type: this.$route.query.type },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            console.log('[features] network_contact', data)
            this.networkData = this.addPathProperty(data.list)

            this.$nextTick(() => {
              this.$refs.tree.setCheckedKeys(data.ids, true)
            })
          }
        }
      )
    }

    @Watch('networkData')
    normalizeNetworkData() {
      if (this.networkDataNormalized) {
        return
      }
      this.networkDataNormalized = true

      this.addPathProperty(this.networkData)

      this.$nextTick(() => {
        const ids = this.network_id.map(({ id, supervisor_id }) => `${id}-${supervisor_id}`)
        this.$refs.tree.setCheckedKeys(ids, true)
      })
    }

    @Watch('viewType')
    @Watch('checkedDeivces')
    triggerFilterNode() {
      this.$refs.tree.filter()
    }

    @Watch('viewType')
    @Watch('filterText')
    collapseAllInitially() {
      if (!this.filterText) {
        this.$nextTick(() => {
          this.collapseAll()
        })
      }
    }

    collapseAll() {
      const { nodesMap } = this.$refs.tree.store
      for (const key of Object.keys(nodesMap)) {
        nodesMap[key].expanded = false
      }
    }

    expandAll() {
      const { nodesMap } = this.$refs.tree.store
      for (const key of Object.keys(nodesMap)) {
        nodesMap[key].expanded = true
      }
    }

    /** @override */
    filterNode(value, data, node) {
      const text = this.filterText

      let visible = (
        this.viewType === 0 && !node.checked ||
        this.viewType === 1 && node.checked ||
        this.viewType === 2
      )

      if (!visible) {
        return false
      }

      if (node.level === 2) {
        return !text || data.name.indexOf(text) !== -1
      }

      if (node.level === 3) {
        return (
          node.parent.visible &&
          this.checkedDeivces.includes(data.device_model)
        )
      }

      return false
    }

    addPathProperty(list) {
      for (const item of list) {
        if ('supervisor_id' in item) {
          item.path = `${item.id}-${item.supervisor_id}`
        } else
        if ('agent_id' in item) {
          item.path = `sp-${item.id}`
        } else {
          item.path = `ag-${item.id}`
        }

        if (item.list) {
          this.addPathProperty(item.list)
        }
      }

      return list
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: headerView } = root.query({ as: <mainheader /> }).next()

      headerView?.replaceChildren(
        <span slot="header-title">{ this.title }</span>
      )

      const { value: treeView } = root.query({ as: <el-tree class="filter-tree" /> }).next()

      if (treeView) {
        treeView.props.nodeKey = 'path'
      }

      const { value: siblingView } = root.query({ as: <el-input placeholder="输入关键字进行过滤" /> }).next()

      siblingView?.before(
        <div style="display: flex; line-height: 40px; height: 40px; margin-top: 10px;">
          <span style="margin-right: 1em; width: 80px;">设备型号: </span>
          <el-checkbox-group value={ this.checkedDeivces } onInput={ e => (this.checkedDeivces = e) } label="按摩椅类型">
            {
              this.deviceOptions.map(name => <el-checkbox label={ name } />)
            }
          </el-checkbox-group>
        </div>
        ,

        <div style="display: flex; line-height: 40px; height: 40px; margin-bottom: 10px;">
          <span style="margin-right: 1em; width: 80px;">状态22: </span>
          <el-radio-group value={ this.viewType } onInput={ e => (this.viewType = e) } style="line-height: 40px;">
            <el-radio label={ 1 }>已选</el-radio>
            <el-radio label={ 0 }>未选</el-radio>
            <el-radio label={ 2 }>全部</el-radio>
          </el-radio-group>
        </div>
      )

      return vnode
    }

  }


  return FreeChargeNetworkView
}
