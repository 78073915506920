import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

export
function PackageCardDetailViewProvider(SuperComponent) {
  @Component()
  class PackageCardDetailView extends SuperComponent {
    // cardTypes = {
    //   1: '普通卡',
    //   2: '专享卡',
    //   3: '销售专享卡',
    //   4: '手机验证卡',
    // }

    loading = false

    /** @override */
    initDiscount() {
      this.editDiscountPackageCard(this.$route.params.id).then(data => {
        this.form = {
          ...data,

          Issue_number     : data.number, // 发行数量
          validity         : data.validity_days, // 有效期
          usable_model     : JSON.parse(data.usable_model), // 可用设备
          set_package      : {
                               number: data.package_num,
                               time:   data.minute,
                             }, // 设置套餐
          give_package     : {
                               number: data.auxiliary_num,
                               time:   data.auxiliary_minute,
                             }, // 设置赠送套餐

          details          : data.desc, // 套餐卡说明
          supervisor_ids   : data.supervisor_ids,
          slide            : data.package_card_slide,
          auxiliary_coupon : data.coupon_list?.length > 0 ? data.coupon_list : null,

          title            : data.title,
          button_img       : data.button_img,
        }
      })
    }

    handleSubmit() {
      ajax(
        '/api/update_button',
        {
          method: 'POST',
          body: {
            id: this.$route.params.id,
            button_img: this.form.button_img,
          }
        },

        error => {
          if (error) {
            this.$message({ message: error.message, type: 'error' })
          } else {
            this.$message({ message: '提交成功', type: 'success' })
          }
        }
      )
    }

    handleUpload(data) {
      this.doUploadFile(data.file, path => {
        this.form.button_img = path
      })
    }

    doUploadFile(file, callback) {
      const formData = new FormData()

      formData.append('image', file)

      this.$http
        .post('/api/upload_image', formData, { emulateJSON: true })
        .then(
          res => {
            if (res.body.code === 200) {
              callback(res.body.data.path)
            } else {
              this.$message.error(res.body.msg)
            }
          },
          res => {
            this.$message.error(res.body.msg ?? res.body.data.msg)
          }
        )
    }

    // @Borrow
    beforeImageUpload(file) {
      if (!/^image\//.test(file.type)) {
        this.$message.error(`上传图片格式非法!`)

        return false
      }

      const MAX_SIZE = 2 * (1 << 20)

      if (file.size > MAX_SIZE) {
        this.$message.error("上传图片大小不能超过 2MB!")

        return false
      }

      return true
    }

    isVipCard(card) {
      const type = Number(card.type)

      return type === 3 || type === 4
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: sibilingView1 } = root.query({ as: <el-form-item label="套餐名称：" /> }).next()

      sibilingView1?.after(
        <el-form-item label="套餐别名：">{ this.form.title }</el-form-item>
      )

      const { value: sibilingView2 } = root.query({ as: <el-form-item label="套餐售价：" /> }).next()

      sibilingView2?.after(
        <el-form-item label="套餐售价：">{ this.form.pay_amount }</el-form-item>
        ,

        <el-form-item label="套餐原价：">{ this.form.price }</el-form-item>
      )
      sibilingView2?.remove()

      const { value: sibilingView3 } = root.query({ as: <el-form-item label="套餐卡类型：" /> }).next()

      // sibilingView3?.before(
      //   <el-form-item label="套餐卡类型：">{ this.cardTypes[this.form.type] }</el-form-item>
      // )

      if (this.isVipCard(this.form)) {
        sibilingView3?.after(
          <el-form-item label="绑定设备号：">{ this.form.device_code }</el-form-item>
        )
      }

      sibilingView3?.remove()

      const { value: sibilingView4 } = root.query({ as: <el-form-item label="卡底图图片：" /> }).next()

      sibilingView4?.before(
        <el-form-item label="购买按钮图片：" rules={ this.rules }>
          <el-upload
            class="purchase-button-uploader ad-uploader"
            action="/api/upload_image"
            http-request={ this.handleUpload.bind(this) }
            show-file-list={ false }
            before-upload={ this.beforeImageUpload.bind(this) }
          >
            {
              this.form.button_img ?
                <img src={ this.form.button_img } class="purchase_img" /> :
                <i class="el-icon-plus avatar-uploader-icon" />
            }
          </el-upload>
        </el-form-item>
      )

      root.query({ as: <el-form-item label="弹窗底图图片：" /> }).next(view => view?.remove())

      const { value: sibilingView5 } = root.query({ as: <el-form-item label="弹窗底图图片：" /> }).next()

      sibilingView5?.remove()

      const { value: sibilingView6 } = root.query({ as: <el-form-item label="轮播图片：" /> }).next()

      sibilingView6?.remove()

      const { value: footerView } = root.query({ as: <div class="box-footer" /> }).next()

      footerView?.children?.unshift(
        <el-button type="primary" onClick={ () => this.handleSubmit() } loading={ this.loading }>提交</el-button>
      )

      return vnode
    }
  }


  return PackageCardDetailView
}
