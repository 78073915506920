import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

export
function NetworkCreateViewProvider(SuperComponent) {
  @Component()
  class NetworkCreateView extends SuperComponent {
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: siblingView } = root.query({ as: <el-form-item label="所属经销点" /> }).next()

      siblingView?.after(
        <el-form-item label="设备类型" rules={ this.rules }>
          <el-select placeholder="请选择设备类型" value={ this.dialog.type || '' } onInput={ e => (this.dialog.type = e) } size="small" filterable clearable>
            <el-option label="小按摩椅" value={ 1 } />
            <el-option label="大按摩椅" value={ 2 } />
          </el-select>
        </el-form-item>
        ,

        <el-form-item label="设备型号" rules={ this.rules }>
          <el-input value={ this.dialog.device_model } onInput={ e => (this.dialog.device_model = e) }/>
        </el-form-item>
      )

      return vnode
    }
  }


  return NetworkCreateView
}
