import { router } from '~/app/router'

import { last } from 'lodash-es'

const page = last(router.getMatchedComponents({ name: 'home' }))
const pageOptions = page.$options ?? page
const { methods: { drawChart } } = pageOptions

const themeStorage = {}

const echarts = {
  init(el, theme) {
    if (!el.hasAttribute('id')) {
      el.id = `chart_${Date.now()}`
    }

    const context = { chart: null, chartData: {} }

    drawChart.call(context, el.id)

    const { chart } = context

    if (theme) {
      if (typeof theme === 'string') {
        theme = themeStorage[theme]
      }

      if (typeof theme === 'object' && theme) {
        chart._theme = clone(theme)
      }
    }

    return chart
  },

  registerTheme(name, theme) {
    themeStorage[name] = theme
  },
}

export
default
echarts

function clone(value) {
  return JSON.parse(JSON.stringify(value))
}
