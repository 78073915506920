import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { assign } from 'lodash-es'

export
function VoteShowViewProvider(SuperComponent) {
  @Component()
  class VoteShowView extends SuperComponent {
    form = assign(
      this.$route.params.data,
      {
        date: [ this.$route.params.data?.gmt_start ?? '', this.$route.params.data?.gmt_end ?? '' ],
        details: this.$route.params.data?.desc,
      }
    )

    imageUrl = this.form.img

    created() {
      if (!this.$route.params.data) {
        this.$router.replace({ name: 'vote' })
      }
    }

    // stub
    handleUpload() {

    }

    // stub
    handleUpload2() {

    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: headerView } = root.query({ as: <mainheader /> }).next()

      headerView?.replaceChildren?.(
        <span slot="header-title">调查问卷 { this.form.name }</span>
      )

      const { value: formView } = root.query({ as: <el-form /> }).next()

      if (formView) {
        formView.props.disabled = true
      }

      for (const columnView of Array.from(root.query({ as: <el-form-item /> }))) {
        columnView.props.disabled = true

        const child = columnView.firstElementChild?.()

        if (child) {
          child.props.readonly = true
        }
      }

      const { value: footerView } = root.query({ as: <div class="box-footer" /> }).next()

      footerView?.remove()

      return vnode
    }
  }


  return VoteShowView
}
