import { Component, Watch } from '~/commons/component/decorators'

import discount from './discount.json'

export
function DiscountIndexViewProvider(SuperComponent) {
  @Component()
  class DiscountIndexView extends SuperComponent {
    typeList = discount.types

    /** @override */
    getType(item) {
      for (const info of this.typeList) {
        if (item.type === info.id) {
          return info.name
        }
      }

      return super.getType(item)
    }
  }


  return DiscountIndexView
}
