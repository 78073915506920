export
const TARGET = (
  document.domain === 'smart.sofocn.com' ?    'sofoshare' :
  document.domain === 'smart.sofoshare.com' ? 'sofoshare' :
  document.domain === 'isf.sofocn.com' ?      'sofoiot' :
  document.domain === 'isf.sofoiot.com' ?     'sofoiot' : null
)

export
const STAGE = (
  document.domain === 'smart.sofocn.com' ?    'testing' :
  document.domain === 'smart.sofoshare.com' ? 'release' :
  document.domain === 'isf.sofocn.com' ?      'testing' :
  document.domain === 'isf.sofoiot.com' ?     'release' : null
)

export
const HOST = TARGET && `${TARGET}.com`

export
const OPERATE_HOST = (
  // 'operate.sofoshare.com'
  STAGE === 'testing' ? 'operate.sofocn.com' :
  STAGE === 'release' ? 'operate.sofoshare.com' : null
)

export
const REPAIR_HOST = (
  STAGE === 'testing' ? 'repair.sofocn.com' :
  STAGE === 'release' ? 'repair.sofoshare.com' : null
)

export
const QRCODE_IMAGE_URL = (
  TARGET === 'sofoshare' ? '/img/home/wechat_qrcode_share_2.png' :
  TARGET === 'sofoiot' ?   '/img/home/wechat_qrcode_iot_2.png' : null
)

export
const OFFICIAL_ACCOUNT_URL = (
  TARGET === 'sofoshare' ? 'https://mp.weixin.qq.com/s?__biz=MzU5NzQ1NjA2NA==&mid=2247498819&idx=1&sn=0bde942ee6a0eef31fdb6a39c0fbd2c3&chksm=fe5199ecc92610fad81543fe113256420727a9943825363feb247554e4ab8ab101e21ad60079#rd' :
  TARGET === 'sofoiot' ?   'https://mp.weixin.qq.com/s?__biz=MzIzODgzOTI1NQ==&mid=2247504055&idx=1&sn=a3b2ad77e200d649154bf5298fc690f6&chksm=e931a40fde462d1904300f6663767eae866bb18731b672f17049115649d32a5a734992b01eba#rd' : '#'
)
