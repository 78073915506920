import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch, Prop } from '~/commons/component/decorators'

import echarts from 'echarts'

import theme from './etc/chart/theme.json'

@Component()
export
class Chart extends ReactiveComponent {
  @Prop()
  data

  chart

  mounted() {
    echarts.registerTheme('sofo', theme)
    this.chart = echarts.init(this.$refs.container, 'sofo')

    this.updateChart()
  }

  @Watch('data')
  updateChart() {
    if (this.chart && this.data) {
      this.chart.setOption(this.extractOptions(this.data))
    }
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  }

  extractOptions(data) {
    return mergeOptions(data.extends, data)
  }

  render() {
    return <div class="line-chart" ref="container" style="width: 100%; height: 100%;"></div>
  }
}

function mergeOptions(source, target) {
  if (!source) {
    return target
  }
  if (!target) {
    return source
  }

  const result = {}

  for (const key of Object.keys(source)) {
    if (!hasOwn(target, key)) {
      result[key] = source[key]
      continue
    }

    if (isPlainObject(source[key]) && isPlainObject(target[key])) {
      result[key] = mergeOptions(source[key], target[key])
    } else {
      result[key] = target[key]
    }
  }

  for (const key of Object.keys(target)) {
    if (!hasOwn(source, key)) {
      result[key] = target[key]
    }
  }

  return result
}

function isPlainObject(obj) {
  return obj && Object.prototype.toString.call(obj) === '[object Object]'
}

function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}
