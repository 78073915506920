import { FreeChargeFollowViewProvider } from '~/pages/freecharge/follow'
import { FreeChargeNetworkViewProvider } from '~/pages/freecharge/network'
import { FreeChargeDataView } from '~/pages/freecharge/data'
import { FreeChargeIndexViewProvider } from '~/pages/freecharge/index'
import { PackageCardIndexViewProvider } from '~/pages/user/package-card-index'
import { PackageCardCreateViewProvider } from '~/pages/user/package-card-create'
import { PackageCardDetailViewProvider } from '~/pages/user/package-card-detail'
import { PackageCardPurchasedViewProvider } from '~/pages/user/package-card-purchased'
import { CouponOrderListViewProvider } from '~/pages/coupon/order-list'
import { CouponListViewProvider } from '~/pages/coupon/coupon-list'
import { VoteIndexViewProvider } from '~/pages/vote/index'
import { VoteCreateViewProvider } from '~/pages/vote/create'
import { VoteShowViewProvider } from '~/pages/vote/show'
import { SupervisorIndexViewProvider } from '~/pages/supervisor/index'
import { SupervisorEditViewProvider } from '~/pages/supervisor/edit'
import { NetworkEditViewProvider } from '~/pages/network/edit'
import { NetworkCreateViewProvider } from '~/pages/network/create'
import { DeviceEditViewProvider } from '~/pages/device/edit'
import { DeviceNewViewProvider } from '~/pages/device/new'

import { DiscountCreateViewProvider } from '~/pages/discount/create'
import { DiscountDetailViewProvider } from '~/pages/discount/detail'
import { DiscountIndexViewProvider } from '~/pages/discount/index'

import { BillsReportView } from '~/pages/reports/bills'
import { ErpPayListView } from '~/pages/reports/erp-pay'
import { ErpCollectListView } from '~/pages/reports/erp-collect'

import { EditDialogProvider } from '~/pages/partner/assets/edit-dialog'

import { OrderDiffView } from '~/pages/order/diff'

import { DetectCreateViewProvider } from '~/pages/detect/create'

import { PackageEditViewProvider } from '~/pages/supervisor/widgets/package-edit'

import { Cooperation } from '~/widgets/Cooperation'

import { ComposePackageIndexView } from '~/pages/composePackage/index'
import { ComposePackageAddOrEditView } from '~/pages/composePackage/add-or-edit'
import { ComposePackageUserView } from '~/pages/composePackage/user'
import { ComposePackageExchangeView } from '~/pages/composePackage/exchange'

export
const routes = [
  {
    path: '/admin',

    children: [
      {
        path: 'free_charge',

        children: [
          {
            name: 'free_charge',
            provider: FreeChargeIndexViewProvider,
          },

          {
            name: 'free_charge.follow',
            provider: FreeChargeFollowViewProvider,
          },

          {
            name: 'free_charge.network',
            provider: FreeChargeNetworkViewProvider,
          },

          {
            name: 'free_charge.data',
            path: 'data',
            component: FreeChargeDataView,
          },
        ]
      },

      {
        path: 'package_card',

        children: [
          // {
          //   name: 'package_card',
          //   provider: PackageCardIndexViewProvider,
          // },

          {
            name: 'package_card.create',
            provider: PackageCardCreateViewProvider,
          },

          {
            name: 'package_card.detail',
            provider: PackageCardDetailViewProvider,
          },

          {
            name: 'package_card.purchased',
            provider: PackageCardPurchasedViewProvider,
          },
        ]
      },

      {
        path: 'vote',

        children: [
          {
            name: 'vote',
            provider: VoteIndexViewProvider,
          },

          {
            name: 'vote.create',
            provider: VoteCreateViewProvider,
          },

          {
            name: 'vote.show',
            path: 'show',

            extends: { name: 'vote.create' },

            provider: [ VoteCreateViewProvider, VoteShowViewProvider ],
          },
        ]
      },

      {
        path: 'coupon',

        children: [
          {
            name: 'coupon',
            provider: CouponListViewProvider,
          },

          {
            name: 'orderList',
            provider: CouponOrderListViewProvider,
          },
        ]
      },

      {
        path: 'supervisor',

        children: [
          {
            name: 'supervisor',
            provider: SupervisorIndexViewProvider,
          },

          {
            name: 'supervisor.edit',
            provider: SupervisorEditViewProvider,

            asset: {
              components: {
                Cooperation,
              },
              providers: {
                'Package.Edit': PackageEditViewProvider,
              },
            },
          },

          {
            name: 'supervisor.create',
          }
        ]
      },

      {
        path: 'network',

        children: [
          {
            name: 'network.create',
            provider: NetworkCreateViewProvider,
          },

          {
            name: 'network.edit',
            provider: NetworkEditViewProvider,

            asset: {
              components: {
                Cooperation,
              }
            },
          },
        ]
      },

      {
        path: 'device',

        children: [
          {
            name: 'device.edit',
            provider: DeviceEditViewProvider,
          },

          {
            name:'device_new',
            provider:DeviceNewViewProvider
          }
        ],
      },

      {
        path: 'partner',

        children: [
          {
            name: 'partner',

            asset: {
              providers: {
                'siteCollaborate.Edit': EditDialogProvider,
                'counselorCollaborate.Edit': EditDialogProvider,
                'maintenanceCollaborate.Edit': EditDialogProvider,
              }
            },
          },
        ]
      },


      {
        path: 'order',

        children: [
          {
            name: 'order.diff',
            path: 'diff',
            component: OrderDiffView,
          },
        ]
      },

      {
        path: 'detect',

        children: [
          {
            name: 'detect.create',
            provider: DetectCreateViewProvider,
          },
        ]
      },

      {
        path: 'discount',

        children: [
          {
            name: 'discount',
            provider: DiscountIndexViewProvider,
          },
          {
            name: 'discount.create',
            provider: DiscountCreateViewProvider,
          },
          {
            name: 'discount.detail',
            provider: DiscountDetailViewProvider,
          },
        ]
      },


      {
        name: 'bills',
        path: 'bills',
        component: BillsReportView,
      },

      {
        name: 'erp-pay',
        path: 'erp-pay',
        component: ErpPayListView,
      },

      {
        name: 'erp-collect',
        path: 'erp-collect',
        component: ErpCollectListView,
      },

      {
        path:'compose_package',
        component: {
          render() {
            return <router-view />
          }
        },
        children : [
         {
          path:'',
          name:'compose_package',
          component: ComposePackageIndexView,
         },
         {
          path:'add_edit',
          name:'compose_package.add_edit',
          component : ComposePackageAddOrEditView
         },
         {
          path:'user',
          name:'compose_package.user',
          component : ComposePackageUserView
         },
         {
          path:'exchange',
          name:'compose_package.exchange',
          component : ComposePackageExchangeView
         }
        ]
      }
    ],
  }

]
