import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import discount from './discount.json'

export
function DiscountDetailViewProvider(SuperComponent) {
  @Component({
    filters: {
      getTypeTxt(value) {
        for (const info of discount.types) {
          if (value === info.id) {
            return info.name
          }
        }

        return '未知'
      }
    }
  })
  class DiscountDetailView extends SuperComponent {
    typeList = discount.types

    /** @override */
    getWeekDetail() {
      if (this.discount.week && !Array.isArray(this.discount.week)) {
        this.discount.week = [ this.discount.week ]
      }

      super.getWeekDetail()
    }

    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const hasTimeRangeInfo = this.discount.type === 5

      if (hasTimeRangeInfo) {
        const { value: referenceItemView } = root.query({ as: <el-form-item label="折扣分类：" /> }).next()

        referenceItemView?.after(
          <el-form-item label="星期："> { this.week.join(', ') } </el-form-item>
          ,
          <el-form-item label="时间段：">{ this.discount.limit_start }——{ this.discount.limit_end }</el-form-item>
        )
      }

      return vnode
    }
  }


  return DiscountDetailView
}
